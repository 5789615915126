import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'
import Layout from "../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

class Etusivu extends React.Component {
  render() {
  const page = get(this, 'props.data.page')
  const { databaseId } = page

  return (
    <Layout
      bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo 
        title={page.seo.title} description={page.seo.metaDesc} 
        canonical={`https://www.varmalaina.com/`}
      />
      <article
        className={`frontpage page type-page status-publish hentry`}
      > 
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1
              className="entry-title"
              dangerouslySetInnerHTML={{ __html: page.title }}
            />
          </div>
        </header>
        <div className="post-inner thin">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
      </article>
    </Layout>
  )
  }
}
export default Etusivu

export const query = graphql`
query FrontPage {
    page: wpPage(title: {eq: "Varma laina"}) {
      id
      title
      content
      databaseId
      seo {
        metaDesc
        title
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          slug
        }
      }
    }
  }
`